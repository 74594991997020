// const localaddress = "http://localhost"

// export const backEndIP = localaddress + ":4000"

// export const clientSocketIP = localaddress + ":7560"

export const backEndIP ="https://admin.techeagle.in"

export const clientSocketIP ="https://admin.ws7560.techeagle.in"

