const webCreateOrder = (body) => {

    console.log('Order Created:', body);
};

const webUpdateOrder = (body) => {

    console.log('Order Updated:', body);
};

const webCancelOrder = (body) => {

    console.log('Order Cancelled:', body);
};

const webPayOrder = (body) => {

    console.log('Order Paid:', body);
    
};

const webCreateCustomer = (body) => {

    console.log('Customer Created:', body);
};

const webUpdateCustomer = (body) => {

    console.log('Customer Updated:', body);
};

const webCreateFulfillment = (body) => {

    console.log('Fulfillment Created:', body);
};

const webUpdateFulfillment = (body) => {

    console.log('Fulfillment Updated:', body);
};

export { webCreateOrder, webCancelOrder, webPayOrder, webUpdateOrder, webCreateCustomer, webUpdateCustomer, webCreateFulfillment, webUpdateFulfillment }