import React from 'react'
import TableColumnForOrderDetails from '../tableColumnForOderDetails/tableColumnForOrderDetails';
import "./orderDetailModel.css"

const OrderDetailModel = ({order}) => {

    const tableHeadings=[{name:'Sr. No.', className:"sr_number_td"}, {name:"Name", className:"item_name_td"}, {name:"Weight", className:"itme_weight_td"}, {name:"Volumetric Weight", className:"item_vweight_td"}, {name:"Quantity", className:"itme_quantity_td"}, {name:"Total Weight", className:"item_total_weight_td"}];
  return (
    <div className='order_detail_model'>
        <div className='address_and_order_id'>
        <div>
        <h1 className='order_id'>Order Id : {order?.id}</h1>
        <p className='total_items'>Total Items : {order?.line_items.reduce((acc, item)=> acc+item.quantity,0)}</p>
        </div>
       <div className='shipping_address'>
        <h3>Shipping Address : </h3>
        <p>Name : {order.customer.first_name} {order.customer.last_name}</p>
        <p>{order.shipping_address.address1}, {order.shipping_address.address2}</p>
        <p>{order.shipping_address.city}, {order.shipping_address.province}, {order.shipping_address.zip}</p>
        <p>Email : {order.customer.email}</p>
        <p>Phone No : {order.customer.phone} </p>
       </div>
        </div>
        <div>
            <table className='order_details_table'>
                <thead>
                <tr className='order_details_table_heading'>
                {
                    tableHeadings.map(heading=><th key={heading.name} className={`order_detail_table_headings ${heading.className}`}>{heading.name}</th>)
                }
                </tr>
                </thead>
                <tbody>
                {
                    order?.line_items.map((item, ind)=><TableColumnForOrderDetails ind={ind} key={item.id} item={item} />)
                }
                </tbody>
            </table>
        </div>
        {/* <h2 className='cart_weight'>Cart Weight : {order?.line_items.reduce((acc, item)=> acc+(item.quantity*item.grams),0)} g</h2> */}
    </div>
  )
}

export default OrderDetailModel;