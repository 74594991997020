import React from 'react'
import "./tableColumnForOrderDetails.css"

const TableColumnForOrderDetails = ({ item, ind }) => {
    return (
        <tr className='order_detail_tr'>
            <td className='order_detail_td'>{ind + 1}</td>
            <td className='order_detail_td'>{item.name}</td>
            <td className='order_detail_td'>{item.grams} g</td>
            <td className='order_detail_td'>{item.grams} g</td>
            <td className='order_detail_td'>{item.quantity}</td>
            <td className='order_detail_td'>{item.quantity * item.grams} g</td>
        </tr>
    )
}

export default TableColumnForOrderDetails;