import React, { useEffect, useState } from 'react';
import axios from 'axios';
import io from "socket.io-client";
import { backEndIP, clientSocketIP } from '../../configuration';
import { webCreateOrder, webCancelOrder, webPayOrder, webUpdateOrder, webCreateCustomer, webUpdateCustomer, webCreateFulfillment, webUpdateFulfillment } from '../../utitlty/updateTopics'
import Order from '../../components/order/order.js';
import './orderPage.css'

const handleWebhooksTopics = {
    "orders/create": webCreateOrder,
    "orders/update": webUpdateOrder,
    "orders/cancelled": webCancelOrder,
    "orders/paid": webPayOrder,
    "customers/create": webCreateCustomer,
    "customers/update": webUpdateCustomer,
    "fulfillments/create": webCreateFulfillment,
    "fulfillments/update": webUpdateFulfillment,
};

const OrderPage = () => {
    const [orders, setOrders] = useState([]);
    const [ordersCopy, setOrdersCopy]=useState([]);
    const [findOrderId, setFindOrderId] = useState("");

    function handleSearch() {
        const selectedOrders = orders.filter(order =>
            order.orderId.includes(findOrderId)
        );
        setOrdersCopy(selectedOrders);
    }
    const [socket, setSocket] = useState(io(clientSocketIP, {
        autoConnect: false,
        transports: ["websocket", "polling"],
        // auth: {
        //     token: userCookie.token,
        //     page: "monitor",
        // },
    }))
    const [isSocketConnected, setIsSocketConnected] = useState(false);

    const fetchOrders = async () => {
        try {
            const response = await axios.get(`${backEndIP}/all-orders`);
            console.log(response.data);
            setOrders(response.data || []);
            setOrdersCopy(response.data || []);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };


    useEffect(() => {
        socket.connect()
        const consoleData = (data) => {
            console.log(data)
        }
        socket.on('connect', () => {
            setIsSocketConnected(true)
            consoleData('connect', "socket connected")
        });


        socket.on('pong', () => {
            consoleData('pong', 'socket pinged')
        });

        socket.on('server:update-broadcast', (data) => {
            consoleData('server:update-broadcast', data?.topic)
            const handler = handleWebhooksTopics[data?.topic];
            if (handler) {
                handler(data.body);
                fetchOrders();
            } else {
                consoleData('Webhook topic not supported');
            }
        });

        socket.on('disconnect', () => {
            setIsSocketConnected(false);
            consoleData("disconnect", "socket disconnected")
        });

        return () => {
            socket.off("connect");
            socket.off("disconnect");
            socket.off("pong");
            socket.off("server:update-broadcast");
            socket.disconnect();
            socket.close()
        }
    }, [socket])

    useEffect(() => {
        fetchOrders()
    }, []);
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await axios.get("http://localhost:3002/customers/6976702480519/orders");
    //             console.log(response.data);
    //             setOrders(response.data.orders || []); 
    //         } catch (error) {
    //             console.error("Error fetching data:", error);
    //         }
    //     };
    //     fetchData();
    // },[]);
    return (
        <div className='order_page'>
            <div className='order_page_header'>
                <div className='order_page_headings'>
                    <h1 className='order_page_all_orders'>All Orders</h1>
                    <p>Total Orders: {orders.length}</p>
                </div>
                <div className='search_tab'>
                    <input className='input_order_id' type="text" value={findOrderId} onChange={(e) => setFindOrderId(e.target.value)} />
                    <button className='search_btn' onClick={handleSearch}>Search</button>
                </div>
            </div>
            <div className='order_container'>
                {
                    orders.length > 0
                        ? ordersCopy.map((order) => <Order key={order.id} order={order.orderData} />)
                        : <p>No orders found.</p>
                }
            </div>
        </div>
    );
};

export default OrderPage;
