import axios from 'axios'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import './logoutBtn.css'



const LogoutBtn = () => {
 const {setToken, name}=useContext(UserContext);
 const navigate=useNavigate();

    const handleLogout=async() => {
         try {
            // const data=await axios.get('/api/users/logout');
            //  console.log(data);
             localStorage.removeItem("token");
             setToken("");
             navigate("/login");
         } catch (error) {
            console.log(error);
         }
    }
  return (
    <div className='logout_btn_div'>
      <div className='name_character'>
        <div>{name.charAt(0)}</div>
      </div>
      <div>
       <button className='logout_btn' onClick={handleLogout}>Logout</button>
      </div>
    </div>
  )
}

export default LogoutBtn;