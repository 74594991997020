import React, { useState } from 'react'

const Product = ({ product }) => {
    const [moreDetails, setMoreDetails] = useState(false);

    function moreDetailsFunction() {
        setMoreDetails(!moreDetails);
    }
    return (
        <div className='product'>
        
            <div className='product_info'>
                <h3 className='product_name'>{product.name}</h3>
                <button className='more_detail_btn' onClick={moreDetailsFunction}>{moreDetails ? <span class="material-symbols-outlined">
                    keyboard_arrow_up
                </span> : <span class="material-symbols-outlined">
                    keyboard_arrow_down
                </span>}
                </button>
            </div>
            {moreDetails && <div className='product_math_data'>

                <div className='date'>
                    <p style={{ color: "#878B8F" }}>Weight : </p>
                    <p>{product.grams} g</p>
                </div>
            
                <div className='date'>
                    <p style={{ color: "#878B8F" }}>Quantity :</p>
                    <p> {product.quantity}</p>
                </div>
            </div>}
        </div>
    )
}

export default Product;