import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
// import AdminOrderPage from './adminOrderPage/adminOrderPage';
import './App.css';
import LoginPage from './pages/loginPage/loginPage';
import SignupPage from './pages/sigupPage/signupPage';
import OrderPage from './pages/order_page/orderPage';
import { useContext, useEffect } from 'react';
import UserContext from './context/UserContext';
import LogoutBtn from './components/logoutBtn/logoutBtn';
import AdminOrderPage from './pages/adminOrderPage/adminOrderPage';

function App() {
  const {token, setToken, setName}  = useContext(UserContext);
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userData'));
   
    if (userData) {
      setToken(userData.token);
      setName(userData.name)
    }
  }, [setToken]);
  return (
    <div className="App">
     {/* <AdminOrderPage /> */}
     <Router>
    {  token && <LogoutBtn />}
      <Routes>
        <Route path="/login" element={!token ? <LoginPage /> : <Navigate to={"/"}/>} />
        <Route path="/signup" element={!token ? <SignupPage /> : <Navigate to={"/"} />} />
        <Route path="/" element={token ? <OrderPage /> : <Navigate to={"/login"} />} />
        {/* <Route path="/" element={token ? <AdminOrderPage /> : <Navigate to={"/login"} />} /> */}
      </Routes>
    </Router>
     
    </div>
  );
}

export default App;
