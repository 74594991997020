import { useState } from "react";
import UserContext from "./UserContext.jsx";


const UserProvider = ({ children }) => {
  const userData = JSON.parse(localStorage.getItem('userData')) || { token: "", name: "" };

  const [token, setToken] = useState(userData.token);
  const [name, setName] = useState(userData.name);
    return (
      <UserContext.Provider value={{ token, setToken, name, setName }}>
        {children}
      </UserContext.Provider>
    );
  };

  export default UserProvider;