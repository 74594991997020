import React, { useState } from 'react'
import ProgressBar from '../progressBar/progressBar';
import Product from '../product/product';
import dayjs from 'dayjs';
import "./order.css"
import OrderDetailModel from '../orderDetailModel/orderDetailModel';

const Order = ({ order }) => {
  const [moreDetails, setMoreDetails] = useState(false);

  function moreDetailsFunction() {
    setMoreDetails(!moreDetails);
  }
  return (
    <div className={!moreDetails ? "order": "order_active"}>
      <div className='order_info'>
        <p className='order_page_order_id'>#{order.order_number}</p>

        {/* <p style={{ color: "#878B8F" }}>Total Price :</p> */}
        <div className='order_price'><p><span class="material-symbols-outlined">
          currency_rupee
        </span></p><p className='price'>{order.total_price}</p>
        </div>

        <div className='order_create_date'>
          <p style={{ color: "#878B8F" }}>Order Date :</p>
          <p>{dayjs(order.created_at).format("ddd, MMM D, YYYY HH:MM A")}</p>
        </div>
        <div className='order_status'>
          {
            order.fulfillments.length > 0 ? <p className='fulfilled_text'>fulfulled</p> : <div className='create_fulfilmment_status'><p className='unfulfilled_text'>Unfulfilled</p><button className='create_fulfillment_btn'>Create fulfillment</button></div>
          }
        </div>
        <button className='more_detail_btn' onClick={moreDetailsFunction}>{moreDetails ? <span class="material-symbols-outlined">
          keyboard_arrow_up
        </span> : <span class="material-symbols-outlined">
          keyboard_arrow_down
        </span>}
        </button>
      </div>
      {moreDetails && <div className='order_details'>
        <div className='order_btns_and_dates'>
          <div className='order_dates'>
            <div className='date'>
              <p style={{ color: "#878B8F" }}>Volumetric Weight : </p>
              <p>{order.total_weight} g</p>
            </div>
            <div className='date'>
              <p style={{ color: "#878B8F" }}>Total Price :</p>
              <p>{order.total_price}</p>
            </div>
            <div className='date'>
              <p style={{ color: "#878B8F" }}>Total Iterms :</p>
              <p>{order.line_items.reduce((accum, product) => accum + product.quantity, 0)}</p>
            </div>
            <div className='date'>
              <p style={{ color: "#878B8F" }}>Total order weight : </p>
              <p>{order.total_weight > 1000 ? (order.total_weight / 1000) : order.total_weight} {order.total_weight > 1000 ? "kg" : "g"}</p>
            </div>
          </div>

        </div>
        <div className='order_address_and_order_id'>
        <div>
        <h1 className='order_id'>Order Id : {order?.id}</h1>
        <p className='total_items'>Total Items : {order?.line_items.reduce((acc, item)=> acc+item.quantity,0)}</p>
        </div>
       <div className='order_shipping_address'>
        <h3>Shipping Address : </h3>
        <p>Name : {order.customer.first_name} {order.customer.last_name}</p>
        <p>{order.shipping_address.address1}, {order.shipping_address.address2}</p>
        <p>{order.shipping_address.city}, {order.shipping_address.province}, {order.shipping_address.zip}</p>
        <p>Email : {order.customer.email}</p>
        <p>Phone No : {order.customer.phone} </p>
       </div>
        </div>
        <div className='show_product'>
          {
          order.line_items.map(product => <Product key={product.id} product={product} />)
          }
        </div>
        <div className='show_order_detail_model'>
          {
            <OrderDetailModel order={order} />
          }
        </div>
      </div>
      }
    </div>
  )
}


export default Order;